/* Footer.css */
.footer {
    padding: 20px;
    background-color: #222;
    color: #fff;
    text-align: center;
    margin-top: 20px;
}

.footer p {
    font-size: 1em;
    margin: 0;
}