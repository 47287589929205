/* VideoBanner.css */
.video_banner {
    position: relative;
    height: 600px;
    /* 增加高度以突出视觉效果 */
    overflow: hidden;
    margin-top: 60px;
}

.video_background {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.video_overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: linear-gradient(to bottom, rgba(234, 231, 231, 0.3), rgba(56, 57, 57, 0.8));
    /* 渐变效果 */
    padding: 20px;
    animation: fadeIn 1.5s ease-out;
    /* 添加淡入动画 */
    z-index: 10;
    /* 增加 z-index，确保它显示在前面 */
}

.video_overlay h1 {
    font-size: 3.5em;
    /* 增大字体以突出标题 */
    color: #fff;
    text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
    /* 增加阴影以增强对比度 */
    margin-bottom: 15px;
    /* 增加标题与副标题之间的间距 */
    animation: slideDown 1s ease-out;
    /* 滑动效果 */
}

.video_overlay p {
    font-size: 1.7em;
    /* 调整副标题大小 */
    color: #f39c12;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.7);
    /* 提高副标题的可见度 */
    animation: slideUp 1s ease-out;
    /* 滑动效果 */
}

/* 响应式设计 */
@media (max-width: 768px) {
    .video_banner {
        height: 400px;
        /* 在小屏幕上调整视频高度 */
    }

    .video_overlay h1 {
        font-size: 2.2em;
        /* 缩小标题字体 */
    }

    .video_overlay p {
        font-size: 1.2em;
        /* 缩小副标题字体 */
    }
}

/* 动画效果 */
@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes slideDown {
    0% {
        transform: translateY(-50px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slideUp {
    0% {
        transform: translateY(50px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}