.image_detail_container {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    flex-wrap: wrap;
    margin-top: 60px;
}

.main_image_gallery {
    flex: 3;
    margin-bottom: 20px;
    text-align: center;
}

.main_video {
    width: 100%;
    max-width: 900px;
    height: auto;
    aspect-ratio: 16 / 9;
    border-radius: 10px;
    transition: transform 0.3s ease;
    background-color: #000;
    /* 设置背景色，避免加载时的空白 */
}

.main_video:hover {
    transform: scale(1.02);
}

.sidebar {
    flex: 1;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 60px;
}

.ad_space img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
}

/* 推荐图片区域的网格布局 */
.related_images_grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* 一行两张图片 */
    gap: 20px;
}

/* 推荐图片容器样式，确保标签和图片正确布局 */
.related_image {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.related_image img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    transition: transform 0.3s ease;
}

.related_image img:hover {
    transform: scale(1.08);
}

/* 图片标题样式 */
.related_image p {
    margin-top: 10px;
    color: #fff;
    font-size: 1em;
    text-align: center;
}

/* 精品标签样式 */
.quality_mark {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: #e74c3c;
    color: #fff;
    font-size: 0.8em;
    padding: 5px 8px;
    border-radius: 3px;
    z-index: 1;
    font-weight: bold;
}

/* VIP 标签样式 */
.vip_mark {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #f39c12;
    color: #fff;
    font-size: 0.8em;
    padding: 5px 8px;
    border-radius: 3px;
    z-index: 1;
    font-weight: bold;
}

.video_container {
    position: relative;
}

.upgrade_prompt {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(3, 3, 3, 0.7) !important;
    /* 半透明背景 */
    color: white;
    z-index: 10;
    font-size: 18px;
    text-align: center;
}

.upgrade_button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #ff9800;
    border: none;
    color: white;
    cursor: pointer;
    border-radius: 5px;
}

.upgrade_button:hover {
    background-color: #e68900;
}

/* PC 端 permission_message 样式 */
.permission_message {
    color: red;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
    background-color: #333;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
}

/* 响应式布局 */
@media (max-width: 768px) {
    .image_detail_container {
        flex-direction: column;
    }

    .main_image_gallery,
    .sidebar {
        flex: unset;
        margin-left: 0;
    }

    .main_video {
        max-width: 100%;
        aspect-ratio: 16 / 9;
    }

    /* 移动端 permission_message 样式 */
    .permission_message {
        font-size: 16px;
        /* 缩小字体 */
        padding: 8px;
        /* 调整 padding */
    }

    .related_images_grid {
        grid-template-columns: repeat(2, 1fr);
        /* 在移动端保持两张图片 */
    }

    .related_image p {
        font-size: 0.9em;
        /* 在小屏幕上缩小字体 */
    }
}