/* 视频展示的网格布局 */
.video_grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    gap: 1.5rem;
    padding: 20px;
    justify-content: center;
}

/* 视频项的样式 */
.video_item {
    position: relative;
    width: 100%;
    max-width: 280px;
    height: auto;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.7);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    background-color: #222;
    display: inline-block;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

/* 鼠标悬停时的放大效果 */
.video_item:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.9);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* 视频缩略图的样式 */
.video_thumbnail {
    width: 100%;
    height: auto;
    border-radius: 10px;
    object-fit: cover;
    position: relative;
}

/* 播放按钮样式，默认显示 */
.play_button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    transition: background-color 0.3s ease;
}

.play_button::before {
    content: '';
    width: 0;
    height: 0;
    border-left: 15px solid #000;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
}

/* 鼠标悬停时播放按钮颜色改变 */
.video_item:hover .play_button {
    background-color: rgba(255, 0, 0, 0.8);
    /* 悬停时播放按钮变为红色 */
}

.video_item:hover .play_button::before {
    border-left-color: white;
    /* 悬停时三角形变为白色 */
}

.video_title {
    margin-top: 8px;
    font-size: 16px;
    color: #ffffff;
    font-weight: bold;
    text-align: center;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
    padding: 4px 0;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 4px;
    width: 100%;
    white-space: nowrap;
    /* 防止换行 */
    overflow: hidden;
    /* 超出部分隐藏 */
    text-overflow: ellipsis;
    /* 使用省略号处理超出文本 */
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

/* VIP 标签样式 */
.vip_tag {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #f39c12;
    color: white;
    padding: 5px 10px;
    font-size: 0.9rem;
    font-weight: bold;
    border-radius: 5px;
    z-index: 10;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.7);
}

.video_showcase h3 {
    font-size: 2.4em;
    /* 增大标题字号 */
    color: #e67e22;
    margin-bottom: 30px;
    margin-left: 30px;
}

.video_showcase h3 a {
    /* 继承 h3 的字号 */
    color: #e67e22;
    /* 继承 h3 的颜色 */
    text-decoration: none;
    /* 去掉下划线 */
    margin-bottom: 30px;
    margin-left: 30px;
}

.video_showcase h3 a:hover {
    color: #d35400;
    /* 鼠标悬停时链接的颜色变化 */
}

/* 精品标签样式 */
.premium_tag {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: #e74c3c;
    /* 火红色，精品标签 */
    color: white;
    padding: 5px 10px;
    font-size: 0.9rem;
    font-weight: bold;
    border-radius: 5px;
    z-index: 10;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.7);
}

/* 悬停时精品标签颜色变化 */
.video_item:hover .premium_tag {
    background-color: #c0392b;
    /* 悬停时火红色加深 */
}

/* 响应式设计 */
@media (max-width: 1440px) {
    .video_grid {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
}

@media (max-width: 1200px) {
    .video_grid {
        grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    }
}

@media (max-width: 768px) {
    .video_grid {
        grid-template-columns: repeat(2, 1fr);
    }

    .video_item {
        max-width: 100%;
    }
}

@media (max-width: 480px) {
    .video_grid {
        grid-template-columns: repeat(1, 1fr);
        justify-items: center;
    }

    .video_item {
        width: 100%;
        max-width: 400px;
    }
}