:root {
    --primary-color: #f39c12;
    --background-color: #111;
    --text-color: #fff;
    --hover-color: #f39c12;
    --header-bg-color: #222;
    --header-fixed-bg-color: rgba(34, 34, 34, 0.9);
    --dropdown-bg-color: #444;
    --dropdown-hover-color: var(--primary-color);
    --dropdown-text-color: #fff;
}

/* 顶部导航栏 */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--header-bg-color);
    padding: 10px 40px;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 100;
    transition: background-color 0.3s ease;
}

.header.scrolled {
    background-color: var(--header-fixed-bg-color);
}

.logo {
    font-size: 1.5em;
    font-weight: bold;
    color: var(--primary-color);
    margin-right: 40px;
}

.logo a {
    color: var(--primary-color);
    text-decoration: none;
}

/* 主菜单 */
.nav {
    display: flex;
    flex-direction: row;
    margin-left: 0;
    background-color: transparent;
    justify-content: flex-start;
    flex-grow: 1;
}

.nav_list {
    list-style: none;
    padding-left: 0;
    margin: 0;
    display: flex;
    gap: 20px;
    /* 增加菜单项之间的间距 */
}

.nav_item {
    position: relative;
}

.nav_item a {
    color: var(--dropdown-text-color);
    text-decoration: none;
    padding: 15px;
    display: block;
    background-color: var(--dropdown-bg-color);
    transition: background-color 0.3s ease;
    border-radius: 5px;
}

.nav_item a:hover {
    background-color: var(--dropdown-hover-color);
}

/* 二级菜单 */
.dropdown_menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    padding: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    z-index: 1000;
    width: 450px;
    max-height: 400px;
    /* 限制二级菜单的最大高度 */
    overflow-y: auto;
    /* 启用垂直滚动 */
    grid-template-columns: repeat(3, 1fr);
    /* 三列显示 */
    gap: 10px;
    grid-auto-rows: auto;
    font-size: small;
    transition: all 0.3s ease-in-out;
}

.dropdown_menu.show {
    display: grid !important;
}

/* 隐藏列表项的默认符号 */
.dropdown_menu li {
    list-style-type: none;
}

/* 用户信息部分 - 注册和登录按钮样式 */
.user_info {
    display: flex;
    gap: 20px;
    /* 增加间距，避免元素过于紧凑 */
    padding-right: 50px;
    /* 确保右侧有足够空白，避免紧贴右边 */
}

.user_info a {
    color: var(--dropdown-text-color);
    text-decoration: none;
    padding: 15px;
    background-color: var(--dropdown-bg-color);
    transition: background-color 0.3s ease;
    border-radius: 5px;
}

.user_info a:hover {
    background-color: var(--dropdown-hover-color);
}

.user_info .profile_link {
    color: var(--primary-color);
    text-decoration: underline;
    font-weight: normal;
    transition: color 0.3s ease;
    background-color: #222;
}

.user_info .profile_link:hover {
    color: #e67e22;
}

/* 菜单按钮 */
.menu_toggle {
    display: none;
    font-size: 1.5em;
    background: none;
    border: none;
    cursor: pointer;
    color: var(--primary-color);
    transition: transform 0.2s ease;
}

.menu_toggle:active {
    transform: scale(0.9);
}

/* 移动端样式 */
@media (max-width: 768px) {
    .nav {
        display: none;
        flex-direction: column;
        position: absolute;
        top: 60px;
        left: 0;
        width: 100%;
        background-color: var(--dropdown-bg-color);
        max-height: 0;
        transition: max-height 0.5s ease;
        overflow: hidden;
    }

    .nav.active {
        display: flex;
        flex-direction: column;
        max-height: 70vh;
        overflow-y: auto;
        margin-top: 10px;
    }

    .nav_list {
        flex-direction: column;
        padding: 0;
        margin: 0;
        gap: 10px;
    }

    .menu_toggle {
        display: block;
    }

    .dropdown_menu {
        grid-template-columns: 1fr;
        width: 100%;
        position: relative;
    }

    .dropdown_menu.show {
        display: block;
    }

    body {
        padding-top: 70px;
    }
}

/* 用户名和退出按钮容器样式 */
.user_info {
    display: flex;
    align-items: center;
    gap: 20px;
    /* 增加间距使得用户名与按钮之间更协调 */
    padding-right: 50px;
    /* 确保右边距充足，避免按钮紧贴页面边缘 */
}

/* 用户名部分样式 */
.username {
    color: var(--text-color);
    font-size: 1rem;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
    /* 限制用户名的最大宽度，避免溢出 */
}

/* 用户名链接样式 */
.profile_link {
    color: var(--primary-color);
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease;
}

.profile_link:hover {
    color: #e67e22;
    /* 悬停时颜色变化，增强交互感 */
}

/* 退出按钮样式 */
.logout_btn {
    padding: 10px 20px;
    margin-right: 30px;
    /* 调整按钮内边距，使其高度与文字一致 */
    background-color: var(--primary-color);
    color: var(--text-color);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    font-size: 1rem;
    /* 确保字体与用户名一致 */
}

.logout_btn:hover {
    background-color: #e67e22;
}

.logout_btn:active {
    transform: scale(0.95);
}