  /* 基础样式 */
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #111;
    color: #fff;
    font-family: 'Arial', sans-serif;
    position: static;
    /* 确保body没有relative定位和z-index */
  }

  /* 遮罩层的基本样式 */
  .overlay {
    position: fixed;
    /* 固定位置，覆盖整个屏幕 */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.778);
    /* 半透明黑色背景 */
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 24px;
    z-index: 1000;
    /* 遮罩层显示在最上层 */
    pointer-events: all;
    /* 启用遮罩层的点击事件，防止点击穿透 */
  }

  .hidden {
    display: none;
  }

  /* 页面内容不可点击或选择 */
  .disable-content {
    pointer-events: none;
    /* 禁止所有点击事件 */
    user-select: none;
    /* 禁止文本选择 */
    opacity: 0.5;
    /* 可选：降低内容的不透明度 */
  }