.image_list_section {
    padding: 20px;
    background-color: #111;
    text-align: center;
}

.image_list_section h2 {
    font-size: 2.5em;
    margin-bottom: 30px;
    color: #f39c12;
    margin-left: 30px;
    text-align: left;
    padding-top: 30px;
}

.image_list_grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 30px;
    justify-items: center;
    padding: 0 20px;
}

.image_item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 16px;
    background-color: #222;
    /* 增加背景色 */
    padding: 10px;
    /* 为内容增加内边距 */
}


.image_thumbnail {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 10px;
    /* 增加图片和文字之间的间距 */
    transition: transform 0.3s ease;
}

.image_item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.7);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.image_item img:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.9);
}

.image_title,
.image_info {
    color: #fff;
    font-size: 1em;
    text-align: center;
    margin: 5px 0;
    /* 控制上下的间距 */
}

.image_titleLink {
    text-decoration: none;
    /* 去掉下划线 */
    color: inherit;
    /* 保持文本颜色一致 */
}

.image_info {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 0.9em;
    padding: 5px 10px;
}

.image_info p {
    margin: 0;
}

.image_info .view_count {
    text-align: right;
    color: #ccc;
    /* 较淡的颜色 */
}

.image_info .img_count {
    text-align: left;
    color: #ccc;
}

/* 图片 hover 效果 */
.image_item:hover .image_thumbnail {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.9);
}

/* 分页控制 */
.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.pagination_btn {
    padding: 10px 20px;
    margin: 0 10px;
    background-color: #f39c12;
    border: none;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.pagination_btn:hover {
    background-color: #e67e22;
}

#pageInfo {
    font-size: 1.2em;
    color: #fff;
}
/* 精品标签样式 */
.quality_mark {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: #e74c3c;
    color: #fff;
    font-size: 0.8em;
    padding: 5px 8px;
    border-radius: 3px;
    z-index: 1;
    font-weight: bold;
}

/* VIP 标签样式 */
.vip_mark {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #f39c12;
    color: #fff;
    font-size: 0.8em;
    padding: 5px 8px;
    border-radius: 3px;
    z-index: 1;
    font-weight: bold;
}

/* 适用于 VIP 和精品同时存在的布局 */
.vip_quality {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: absolute;
    top: 10px;
}
/* 响应式调整 */
@media (max-width: 768px) {

    .image_title,
    .image_info {
        font-size: 0.8em;
        /* 在小屏幕上缩小字体 */
    }
}