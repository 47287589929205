.image_detail_container {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    flex-wrap: wrap;
    margin-top: 60px;
}

.main_image_gallery {
    flex: 3;
    margin-bottom: 20px;
}

.main_image {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 100%;
    /* 保证图片区域最大宽度 */
}

.gallery_image {
    width: 100%;
    height: auto;
    border-radius: 10px;
    max-height: 1800px;
    /* 调整图片的最大高度，提升图片显示大小 */
    object-fit: contain;
    transition: transform 0.3s ease;
}

.gallery_image:hover {
    transform: scale(1.02);
    /* 图片 hover 时的缩放效果 */
}

.sidebar {
    flex: 1;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 60px;
}

.image_titleLink {
    text-decoration: none;
    color: inherit;
    /* 继承文本颜色 */
}

.related_images_grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* 网格布局，2列 */
    gap: 20px;
}

.related_image {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    overflow: hidden;
}

.related_image img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    transition: transform 0.3s ease;
}

.related_image img:hover {
    transform: scale(1.08);
    /* 推荐图片 hover 时的缩放效果 */
}

.related_image p {
    margin-top: 10px;
    color: #fff;
    /* 白色文本 */
}

.quality_mark {
    position: absolute;
    top: 8px;
    left: 8px;
    background-color: #e74c3c;
    color: #fff;
    font-size: 0.8em;
    padding: 5px 8px;
    border-radius: 3px;
    z-index: 1;
    font-weight: bold;
}

.vip_mark {
    position: absolute;
    top: 8px;
    right: 8px;
    background-color: #f39c12;
    color: #fff;
    font-size: 0.8em;
    padding: 5px 8px;
    border-radius: 3px;
    z-index: 1;
    font-weight: bold;
}

.scroll_to_top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #3498db;
    color: white;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
    border: none;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.scroll_to_top:hover {
    background-color: #2980b9;
}

.permission_message {
    color: red;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
    background-color: #333;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
}

.upgrade_button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #ff9800;
    border: none;
    color: white;
    cursor: pointer;
    border-radius: 5px;
}

.upgrade_button:hover {
    background-color: #e68900;
}

@media (max-width: 768px) {
    .image_detail_container {
        flex-direction: column;
    }

    .main_image_gallery,
    .sidebar {
        flex: unset;
        margin-left: 0;
    }

    .gallery_image {
        max-height: 500px;
        /* 移动端时调整图片最大高度 */
    }

    .related_images_grid {
        grid-template-columns: repeat(2, 1fr);
    }

    .related_image p {
        font-size: 0.9em;
    }
}