/* 公共布局样式 */
.register_section,
.login_section,
.payment_section {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #111;
    padding: 20px;
    margin-top: 60px;
}

/* 容器样式 */
.register_container,
.login_container,
.payment_container {
    background-color: #333;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.7);
    max-width: 400px;
    width: 100%;
    text-align: center;
    margin: 20px;
    /* 增加外边距，确保页面内容不会太过紧凑 */
}

/* 标题样式 */
.register_container h2,
.login_container h2,
.payment_container h2 {
    font-size: 2em;
    color: #f39c12;
    margin-bottom: 20px;
}

/* 表单组的样式 */
.form_group {
    text-align: left;
    margin-bottom: 15px;
    /* 增加表单组之间的间距 */
}

.form_group label {
    font-size: 1em;
    color: #fff;
    margin-bottom: 5px;
    display: block;
}

.form_group input {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #444;
    color: #fff;
    margin-bottom: 10px;
    /* 增加输入框下方的间距 */
}

.form_group input::placeholder {
    color: #ccc;
}

/* 提交按钮样式 */
.btn_submit {
    background-color: #f39c12;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    /* 确保按钮占据整个宽度 */
    transition: background-color 0.3s ease;
}

.btn_submit:hover {
    background-color: #e67e22;
}

/* 注册链接样式 */
a.register_link {
    color: #f39c12;
    font-weight: bold;
    text-decoration: none;
    font-size: 1.1em;
    /* 增加字体大小 */
    padding: 10px 0;
    display: inline-block;
    /* 使链接占据空间，更容易点击 */
    transition: color 0.3s ease;
}

a.register_link:hover {
    color: #e67e22;
    text-decoration: underline;
    /* 鼠标悬停时增加下划线 */
}

/* 错误消息样式 */
.error_message {
    color: red;
    font-size: 0.9em;
    margin-top: 10px;
}

/* 响应式设计 */
@media (max-width: 768px) {

    .register_container,
    .login_container,
    .payment_container {
        padding: 20px;
        max-width: 100%;
        /* 在中等屏幕上全宽显示 */
    }

    h2 {
        font-size: 1.5em;
    }

    .form_group input {
        padding: 8px;
    }

    .btn_submit {
        padding: 8px;
    }
}

@media (max-width: 480px) {

    .register_container,
    .login_container,
    .payment_container {
        padding: 15px;
        max-width: 100%;
    }

    h2 {
        font-size: 1.2em;
    }

    .form_group input {
        font-size: 0.9em;
        padding: 6px;
    }

    .btn_submit {
        padding: 8px;
        font-size: 0.9em;
    }
}