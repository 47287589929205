/* src/pages/UpgradeVip.module.css */

/* 全局根布局，确保内容在屏幕中央对齐 */
.root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #202020;
    padding: 20px;
    box-sizing: border-box;
    /* 使用较暗的背景色，适应小屏幕的边距 */
}

/* Header 样式 */
.header {
    width: 100%;
    background-color: #333;
    padding: 20px;
    color: white;
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    /* 为 header 增加阴影，提升层次感 */
}

/* 表格卡片样式 */
.vipUpgradeCard {
    margin-top: 120px;
    /* 增加顶部空间，避免被header遮挡 */
    background-color: rgba(255, 255, 255, 0.9);
    /* 白色背景带有透明度 */
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
    max-width: 900px;
    width: 100%;
    color: #333;
    box-sizing: border-box;
    /* 确保文字颜色与背景色对比清晰 */
}

/* 表格样式 */
.vipTable {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    font-size: 16px;
    /* 调整字体大小，使表格更易读 */
}

.vipTable th,
.vipTable td {
    padding: 14px 16px;
    /* 增加内边距，使表格内容不拥挤 */
    text-align: left;
    border-bottom: 1px solid #ddd;
    word-wrap: break-word;
    /* 让单元格内容在必要时换行，避免溢出 */
}

.vipTable th {
    background-color: #f0f0f0;
    font-weight: bold;
    color: #333;
    /* 深色标题，确保易读 */
}

.vipTable td {
    background-color: #fff;
}

/* 子行（描述信息）样式 */
.expandedRow td {
    padding: 14px;
    background-color: #f9f9f9;
    font-size: 14px;
    color: #555;
    /* 灰色描述信息 */
}

/* 错误消息和加载消息样式 */
.errorMessage,
.loading {
    margin-top: 160px;
    font-size: 20px;
    /* 增大字体，强调错误/加载信息 */
    color: red;
    text-align: center;
}

/* 优化加载状态 */
.loadingContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    color: #ffffff;
}

.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #007bff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
    margin-bottom: 20px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* 支付成功提示样式 */
.paymentMessageSuccess {
    color: #28a745;
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    margin-top: 20px;
    padding: 15px;
    background-color: rgba(40, 167, 69, 0.1);
    border: 1px solid #28a745;
    border-radius: 8px;

    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

/* 支付失败提示样式 */
.paymentMessageError {
    color: #dc3545;
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    margin-top: 20px;
    padding: 15px;
    background-color: rgba(220, 53, 69, 0.1);
    border: 1px solid #dc3545;
    border-radius: 8px;

    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

/* Footer 样式 */
.footer {
    width: 100%;
    background-color: #333;
    color: white;
    padding: 20px;
    text-align: center;
    position: fixed;
    bottom: 0;
    left: 0;
}

/* 为升级按钮设置样式 */
.upgradeButton {
    background-color: #28a745;
    border: none;
    padding: 14px 24px;
    border-radius: 6px;
    color: white;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    transition: background-color 0.3s ease, transform 0.2s ease;
    /* 添加过渡效果，提升交互体验 */
}

.upgradeButton:hover {
    background-color: #218838;
    /* 悬停时变暗 */
    transform: translateY(-2px);
    /* 悬停时的轻微浮动效果 */
}

.upgradeButton:active {
    background-color: #1e7e34;
    /* 点击时更暗 */
    transform: translateY(0);
    /* 点击时恢复原位 */
}

.upgradeButton:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
    /* 禁用状态的按钮样式 */
}

/* 响应式处理 */
@media (max-width: 768px) {

    .paymentMessageSuccess,
    .paymentMessageError {
        font-size: 18px;
        padding: 10px;
    }

    .vipUpgradeCard {
        margin-top: 100px;
        padding: 20px;
        max-width: 100%;
    }

    .vipTable th,
    .vipTable td {
        font-size: 14px;
        padding: 10px 12px;
    }

    .upgradeButton {
        font-size: 16px;
        padding: 12px 20px;
    }

    .footer {
        font-size: 14px;
        padding: 15px;
    }
}